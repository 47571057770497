<template>
  <div class="bigPart">
    <headTitle go="uni" v-if="backShow" />
    <div class="content">
      <div class="content_part">
        <img src="../../assets/images/star.png" alt />
        <div class="content_title">活动</div>
        <div class="content_title1">{{ name}}</div>
      </div>
      <div class="content_part">
        <img src="../../assets/images/headPic.png" alt />
        <div class="content_title">姓名</div>
        <div class="content_title1">
          <span v-if="userData.realName">{{userData.realName}}</span>
          <span v-if="!userData.realName&&userData.nickname">{{userData.nickname}}</span>
        </div>
      </div>
      <div class="content_part">
        <img src="../../assets/images/phone.png" alt />
        <div class="content_title">电话</div>
        <div class="content_title1">{{userData.phone}}</div>
      </div>
    </div>
    <van-button class="buttonPart" @click="getNotice" round>签到录入</van-button>
    <van-popup v-model="successNotice" round>
      <div class="noticeContent">
        <img src="../../assets/images/sign1.png" alt />
        <van-button class="buttonPart1" round @click="toLocationScan()">
          <img src="../../assets/scanPic.png" alt />
          扫码继续录入
        </van-button>
      </div>
    </van-popup>
    <van-popup v-model="defeatNotice" round>
      <div class="noticeContent">
        <img src="../../assets/images/sign2.png" alt />
        <van-button class="buttonPart1" @click="getAgain" round>重新签到录入</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  punchById,
  getScanActivityDetail,
  getScanActivityName

} from '@/api/punchclock'
export default {
  data() {
    return {
      name: '',
      activityName: '',
      activityId: '',
      successNotice: false,
      defeatNotice: false,
      userData: null,
      backShow: false
    }
  },
  created() {
    this.GetRequest()
  },
  mounted() {
    if (this.appName == "爱旌德" || this.appName == "今日和县" || this.appName == "智慧新洲") {
      this.backShow = true
    }
    this.getScanActivityName()
    this.queryActivityDetail()
  },
  methods: {
    toLocationScan() {//扫码跳转
      var msg = { "scanType": 3 }
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {  //安卓
        window.javaInterface.toScanQR(JSON.stringify(msg));
      } else { //苹果
        this.setupWebViewJavascriptBridge(function (bridge) {
          bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
            var responseData = { "Javascript Says": "Right back atcha!" };
            responseCallback(responseData);
          });
          bridge.callHandler("toScanQR", msg, function (response) {
            console.log(response);
          });
        });
      }
    },
    getNotice() {
      //判断签到成功失败
      let params = {
        otherMemberId: this.userId,
        activityId: this.activityId,
      }
      punchById(params)
        .then((res) => {
          if (res.success) {
            this.successNotice = true
          } else {
            this.defeatNotice = true
          }
        })
        .catch((err) => {

        })
    },
    getAgain() {
      this.defeatNotice = false;
      var msg = { "scanType": 3 }
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {  //安卓
        this.returnPage();
      } else { //苹果
        this.setupWebViewJavascriptBridge(function (bridge) {
          bridge.registerHandler("testJavascriptHandler", function (data, responseCallback) { //注册
            var responseData = { "Javascript Says": "Right back atcha!" };
            responseCallback(responseData);
          });
          bridge.callHandler("toScanQR", msg, function (response) {
            console.log(response);
          });
        });
      }
    },
    GetRequest() {
      //输入链接 获取值
      var url
      var urlStr = window.location.href

      //console.log(urlStr)
      if (typeof urlStr == 'undefined') {
        url = decodeURI(location.search) //获取url中"?"符后的字符串
      } else {
        url = urlStr.split('?')[1]
      }
      var strs = url.split('&')
      for (var i = 0; i < strs.length; i++) {
        if (strs[i].split('=')[0] == 'activityName') {
          this.activityName = decodeURI(strs[i].split('=')[1])
        }
        if (strs[i].split('=')[0] == 'activityId') {
          this.activityId = strs[i].split('=')[1]
        }
        if (strs[i].split('=')[0] == 'userId') {
          this.userId = strs[i].split('=')[1]
        }
      }
    },
    //查询活动名称
    getScanActivityName() {
      getScanActivityName({ id: this.activityId }).then((res) => {
        if (res.success) {
          this.name = res.data.name
        }
      })
    },
    // 查询用戶详情
    queryActivityDetail() {
      getScanActivityDetail({ id: this.userId })
        .then((res) => {
          if (res.success) {
            this.userData = res.data

          }
        })
        .catch((err) => {
          console.log(err)
          alert(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.bigPart {
  padding: 15vh 4vw 0;
  .content {
    border-radius: 10px;
    background-color: #0db5f4;
    .content_part {
      display: flex;
      align-items: center;
      padding: 6vw 3vw;
      font-size: 15px;
      color: #ffffff;
      img {
        width: 20px;
        height: 20px;
      }
      .content_title {
        padding-left: 5px;
        flex: 1;
        word-break: keep-all;
      }
      .content_title1 {
        font-weight: bold;
        word-break: break-all;
        padding-left: 15px;
      }
    }
  }
  .buttonPart {
    margin: 20vh auto 0;
    display: block;
    width: 250px;
    background: #10aeef;
    border: solid 1px #10aeef;
    color: #ffffff;
    font-size: 16px;
  }
  .noticeContent {
    padding: 10vw 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 40vw;
    }
    .buttonPart1 {
      width: 3.2rem;
      background: #10aeef;
      border: solid 1px #10aeef;
      color: #ffffff;
      font-family: "Microsoft YaHei";
      font-size: 15px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
